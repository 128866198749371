import { HeadCell } from 'modules/shared/components/table/components/EnhancedTableHead.component';
import {
  CampaignInScheduler,
  InputValue,
  SchedulableEmployees,
  SchedulerStatus,
  SchedulerTypes,
} from './interfaces/Scheduler.interface';
import { CalculatorData } from 'modules/core/providers/InboundCalculator.provider';
import { ShiftInterface, ShiftsTypes } from 'modules/shifts/interfaces/shifts.interface';
import { BodyCell } from 'modules/shared/components/table/Table.component';
import { endOfWeek, format, formatISO, parseISO, startOfWeek, eachWeekOfInterval } from 'date-fns';
import { es } from 'date-fns/locale';
import { Colors, DayOption } from './interfaces/Calendar.interface';
import { CalendarApi, DateInput, EventInput } from '@fullcalendar/core';
import { TypeModal } from 'modules/shared/components/modal/Modal.component';

export const dummyResources = [
  { id: '3', building: 'Thundercats', title: 'Cris' },
  { id: 'a', building: 'Omega', title: 'Brian' },
  { id: 'b', building: 'Omega', title: 'Ari' },
  { id: 'g', building: 'Alfa', title: 'Empleado G' },
  { id: 'h', building: 'Alfa', title: 'Empleado H' },

  { id: 'n', building: 'Alfa', title: 'Empleado N' },
  { id: 'o', building: 'Epsilon', title: 'Empleado O' },
  { id: 'p', building: 'Epsilon', title: 'Empleado P' },
];

export const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];
export const fallbackShift: ShiftInterface[] = [
  {
    id: 'shift1',
    name: 'Morning Shift',
    shift_type: '',
    period_start: '',
    status: true,
    period_count: 0,
    resting_type: '',
    resting_days: '',
    periods: [
      {
        id: 123,
        days: [
          {
            id: 0,
            name: 'sunday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 1,
            name: 'monday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 2,
            name: 'tuesday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 3,
            name: 'wednesday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 4,
            name: 'thursday',
            work: false,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 5,
            name: 'friday',
            work: false,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 6,
            name: 'saturday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
        ],
      },
      {
        id: 456,
        days: [
          {
            id: 0,
            name: 'sunday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 1,
            name: 'monday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 2,
            name: 'tuesday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 3,
            name: 'wednesday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 4,
            name: 'thursday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 5,
            name: 'friday',
            work: false,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 6,
            name: 'saturday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
        ],
      },
      {
        id: 789,
        days: [
          {
            id: 0,
            name: 'sunday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 1,
            name: 'monday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 2,
            name: 'tuesday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 3,
            name: 'wednesday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 4,
            name: 'thursday',
            work: false,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 5,
            name: 'friday',
            work: false,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 6,
            name: 'saturday',
            work: true,
            ingress: '09:00',
            egress: '18:00',
            work_minutes: 540,
            work_hours: 9,
          },
        ],
      },
    ],
    breaks: [
      {
        id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
        name: 'Lunch',
        duration: '90',
        category: 'main',
      },
      {
        id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
        name: 'CAMPITO',
        duration: '5',
        category: 'interval',
      },
    ],
  },
  {
    id: 'shift2',
    name: 'Evening Shift',
    shift_type: '',
    period_start: '',
    status: true,
    period_count: 0,
    resting_type: '',
    resting_days: '',
    periods: [
      {
        id: 321,
        days: [
          {
            id: 0,
            name: 'sunday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 1,
            name: 'monday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 2,
            name: 'tuesday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 3,
            name: 'wednesday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 4,
            name: 'thursday',
            work: false,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 5,
            name: 'friday',
            work: false,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 6,
            name: 'saturday',
            work: true,
            ingress: '10:00',
            egress: '19:00',
            work_minutes: 540,
            work_hours: 9,
          },
        ],
      },
    ],
    breaks: [
      {
        id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
        name: 'Lunch',
        duration: '90',
        category: 'main',
      },
      {
        id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
        name: 'CAMPITO',
        duration: '5',
        category: 'interval',
      },
    ],
  },
  {
    id: 'shift3',
    name: 'Night Shift',
    shift_type: '',
    period_start: '',
    status: true,
    period_count: 0,
    resting_type: '',
    resting_days: '',
    periods: [
      {
        id: 654,
        days: [
          {
            id: 0,
            name: 'sunday',
            work: false,
            ingress: '07:00',
            egress: '16:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 1,
            name: 'monday',
            work: true,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 2,
            name: 'tuesday',
            work: true,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 3,
            name: 'wednesday',
            work: true,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 4,
            name: 'thursday',
            work: true,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 5,
            name: 'friday',
            work: true,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
          {
            id: 6,
            name: 'saturday',
            work: false,
            ingress: '07:00',
            egress: '15:00',
            work_minutes: 540,
            work_hours: 9,
          },
        ],
      },
    ],
    breaks: [
      {
        id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
        name: 'Lunch',
        duration: '90',
        category: 'main',
      },
      {
        id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
        name: 'CAMPITO',
        duration: '5',
        category: 'interval',
      },
    ],
  },
];

export const dummyScheduler = {
  id: '01db0ab7-0ee9-11ef-a218-024c4f6af097',
  name: 'Plan C.entrante uno',
  type: SchedulerTypes.MANUAL,
  status: 'draft' as SchedulerStatus,
  campaign: {
    id: '50b61621-4c10-4fc3-854c-fd9b1aaf05c1',
    name: 'Campaña entrante uno',
    type: 'inbound',
  },
  planner: {
    id: '01db0ab7-0ee9-11ef-a218-024c4f6af097',
    names: 'Sergio',
    surenames: 'Perez',
  },
  teams: [
    {
      id: '060323dc-9b88-4de1-b047-bb1a25bf973a',
      name: 'Equipo Alf',
    },
    {
      id: '160323dc-9b88-4de1-b047-bb1a25bf973b',
      name: 'Equipo Kitt',
    },
  ],
  employees: [
    {
      id: '1234-abcd',
      request_created_by_conflict: false,
      employee: {
        id: '1234-abcd',
        names: 'Willie',
        surenames: '',
        assigned_shift: {
          id: '0eead786-89cc-48e5-80d9-e9558a3cb8e8',
        },
        team_member: [
          // no se si puede ir en más de un team, le pondria directamente 'team' y adentro el id y el isManager
          {
            team: {
              id: '060323dc-9b88-4de1-b047-bb1a25bf973a', // este podría ser directamente team_id si no va a traer mas cosas
            },
            isManager: false,
          },
        ],
      },
      scheduled: [
        {
          date: '2024-05-20',
          from_hour: '09:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
        {
          date: '2024-05-21',
          from_hour: '08:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
      ],
      breaks: [
        {
          date: '2024-05-20',
          id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
          break_category: 'main',
          start_break: '12:30',
          end_break: '13:00',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '11:00',
          end_break: '11:10',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '14:30',
          end_break: '13:00',
        },
      ],
      requests: [],
    },
    {
      id: '5678-abcd',
      request_created_by_conflict: false,
      employee: {
        id: '5678-abcd',
        names: 'Kate',
        surenames: '',
        assigned_shift: {
          id: '0eead786-89cc-48e5-80d9-e9558a3cb8e8',
        },
        team_member: [
          {
            team: {
              id: '060323dc-9b88-4de1-b047-bb1a25bf973a',
            },
            isManager: false,
          },
        ],
      },
      scheduled: [
        {
          date: '2024-05-20',
          from_hour: '09:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
        {
          date: '2024-05-21',
          from_hour: '09:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
      ],
      breaks: [
        {
          date: '2024-05-20',
          id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
          break_category: 'main',
          start_break: '12:30',
          end_break: '13:00',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '11:00',
          end_break: '11:10',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '14:30',
          end_break: '13:00',
        },
      ],
      requests: [],
    },
    {
      id: '9876-defa',
      request_created_by_conflict: false,
      employee: {
        id: '9876-defa',
        names: 'Michael',
        surenames: '',
        assigned_shift: {
          id: '0eead786-89cc-48e5-80d9-e9558a3cb8e8',
        },
        team_member: [
          {
            team: {
              id: '160323dc-9b88-4de1-b047-bb1a25bf973b',
              name: 'Equipo Kitt',
            },
            isManager: false,
          },
        ],
      },
      scheduled: [
        {
          date: '2024-05-20',
          from_hour: '09:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
        {
          date: '2024-05-21',
          from_hour: '09:00',
          to_hour: '16:00',
          color: '#FF5630' as Colors,
        },
      ],
      breaks: [
        {
          date: '2024-05-20',
          id: '18f167ac-e23e-11ee-8c6b-0242ac130002',
          break_category: 'main',
          start_break: '12:30',
          end_break: '13:00',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '11:00',
          end_break: '11:10',
        },
        {
          date: '2024-05-20',
          id: '1de51b16-e23e-11ee-8c6b-0242ac130002',
          break_category: 'interval',
          start_break: '14:30',
          end_break: '13:00',
        },
      ],
      requests: [],
    },
  ],
  erlang: {} as CalculatorData,
  start_date: '2024-05-20',
  end_date: '2024-05-26',
  shifts: [
    {
      id: '0eead786-89cc-48e5-80d9-e9558a3cb8e8',
      name: 'turno de pirulito',
      shift_type: 'regular' as ShiftsTypes,
    },
  ],
};

export const quantityDaysModel = {
  daysToSchedule: 0,
  historicalDays: 0,
};

export const popupFormModel = {
  name: '',
  end_date: formatISO(new Date()),
  start_date: formatISO(new Date()),
  type: SchedulerTypes.MANUAL,
  campaign: {} as CampaignInScheduler,
  teams: [],
};

export const selectedDayModel = {
  firstDay: 'Monday',
  lastDay: 'Sunday',
  indexFirstDay: 1,
  indexLastDay: 0,
  mondayOfTheWeek: new Date(),
  counterForLastSaturday: 0,
  totalDaysToSchedule: 0,
  weeksBeingScheduled: 0,
};

export const getStatus = () => {
  return [
    { id: 'draft', name: 'Borrador' },
    { id: 'published', name: 'Publicado' },
    { id: 'finished', name: 'Finalizado' },
  ];
};

export const dataModalDefault = {
  isOpenModal: false,
  title: '',
  formattedTitle: '',
  content: '',
  loadingBtnConfirm: false,
  typeModal: TypeModal.SUCCESS,
  textBtnConfirm: 'Confirmar',
  handleConfirm: () => null,
};

// ------------------------------------
export const getHeadCellsUsersList = (checked: boolean, onToggleUsers: (checked: boolean) => void): HeadCell[] => {
  return [
    {
      id: 'checkAll',
      displayType: 'checkbox',
      checked: checked,
      width: '70px',
      label: '',
      numeric: false,
      sortable: false,
      onChange: event => onToggleUsers(event.target.checked),
    },
    {
      id: 'fullname',
      label: 'Nombre completo',
      numeric: false,
      sortable: false,
    },
    {
      id: 'shift',
      label: 'Turno',
      numeric: false,
      sortable: false,
    },
    {
      id: 'equipo',
      label: 'Equipo',
      numeric: false,
      sortable: false,
    },
    {
      id: 'campaña',
      label: 'Campaña',
      numeric: false,
      sortable: false,
    },
  ];
};

export const usersToBodyCell = (
  users: SchedulableEmployees[],
  checkedUsers: SchedulableEmployees[],
  onSelectUser: (user: SchedulableEmployees, checked: boolean) => void,
): BodyCell[][] => {
  return users.map(employee => [
    {
      displayType: 'checkbox',
      checked: !!checkedUsers.find(checkedUser => checkedUser.id === employee.id),
      onChange: event => onSelectUser(employee, event.target.checked),
    },
    {
      displayType: 'text',
      text: `${employee.names} ${employee.surenames}`,
    },
    { displayType: 'text', text: employee.assigned_shift ? employee.assigned_shift.name : '-' },
    { displayType: 'text', text: employee.team ? employee.team.name : '-' },
    { displayType: 'text', text: employee.campaign ? employee.campaign.name : '-' },
  ]);
};

export const getHeadCells = (abilities: any): HeadCell[] => {
  const headCells = [
    { id: 'space1', numeric: false, label: '', sortable: false },
    {
      id: 'name',
      numeric: false,
      label: 'Nombre',
      sortable: false,
    },
    {
      id: 'start_date',
      numeric: false,
      label: 'Inicio',
      sortable: false,
    },
    {
      id: 'end_date',
      numeric: false,
      label: 'Finalización',
      sortable: false,
    },
    {
      id: 'planner',
      numeric: false,
      label: 'Planificador',
      sortable: false,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Estado',
      sortable: false,
    },
    {
      id: 'type',
      numeric: false,
      label: 'Tipo',
      sortable: false,
    },
    { id: 'space0', numeric: false, label: '', sortable: false },
  ];
  if (abilities.read_scheduler) {
    const emptySpace = { id: 'space2', numeric: false, label: '', sortable: false };
    headCells.push(emptySpace);
  }
  if (abilities.update_scheduler) {
    const emptySpace = {
      id: 'space4',
      numeric: false,
      label: '',
      sortable: false,
    };
    headCells.push(emptySpace);
  }
  if (abilities.delete_scheduler) {
    const emptySpace = {
      id: 'space3',
      numeric: false,
      label: '',
      sortable: false,
    };
    headCells.push(emptySpace);
  }
  return headCells;
};

// ------------------------------------
export function getListDays(
  startDate: string | Date,
  endDate: string | Date,
  selectedDate: string | Date,
): DayOption[] {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const excludeDate = new Date(selectedDate);

  if (isNaN(start.getTime()) || isNaN(end.getTime()) || isNaN(excludeDate.getTime())) {
    throw new Error('Invalid date format. Please provide valid dates.');
  }

  const dateList: DayOption[] = [];
  const currentDate = new Date(start);

  while (currentDate <= end) {
    const isoDate = currentDate.toISOString().split('T')[0];

    dateList.push({
      date: isoDate,
      value: format(currentDate, "dd 'de' MMMM yyyy", { locale: es }),
      selected: false,
    });

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateList;
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export const parseDateInput = (dateInput: DateInput | undefined): Date => {
  if (dateInput instanceof Date) {
    return dateInput;
  } else if (typeof dateInput === 'string') {
    return parseISO(dateInput);
  } else if (typeof dateInput === 'number') {
    return new Date(dateInput);
  } else {
    throw new Error('Invalid date input');
  }
};

/**
 * Gets the Monday of the week for the given date.
 * @param date - The input date.
 * @returns The date of the Monday of the same week as the given date.
 */
export function getMonday(date: Date): Date {
  return startOfWeek(date, { weekStartsOn: 1 });
}

/**
 * Gets the Sunday of the week for the given date.
 * @param date - The input date.
 * @returns The date of the Sunday of the same week as the given date.
 */
function getSunday(date: Date): Date {
  return endOfWeek(date, { weekStartsOn: 1 });
}

/**
 * Calculates the number of full weeks within the given date range.
 * @param startDate - The start date of the range.
 * @param endDate - The end date of the range.
 * @returns The number of full weeks within the range.
 */
export function calculateEachWeekOfInterval(startDate: Date, endDate: Date): number {
  const startMonday = getMonday(startDate);
  const endSunday = getSunday(endDate);

  // Adjust to the full week range
  const fullWeeks = eachWeekOfInterval(
    {
      start: startMonday,
      end: endSunday,
    },
    { weekStartsOn: 1 },
  );

  return fullWeeks.length;
}

const truncateToMidnight = (date: Date): Date => {
  if (!date) return new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const calculateEmployeesPerHour = (
  eventMap: Map<string | undefined, EventInput> | EventInput[],
  calendarApi?: CalendarApi,
) => {
  const hours = Array(24).fill(0);
  const currentDay = truncateToMidnight(calendarApi?.getDate() as Date);
  const currentEvents: EventInput[] = [];

  eventMap.forEach(event => {
    if (typeof event.date === 'string') {
      const currentEventDate = new Date(event.date.replaceAll('-', '/'));
      if (currentEventDate.getTime() === currentDay?.getTime()) {
        currentEvents.push(event);
      }
    }
  });

  currentEvents.forEach(({ start, end, extendedProps }: EventInput) => {
    if (!start || !end) {
      return;
    }

    const startDate = new Date(start as string);
    const endDate = new Date(end as string);

    const startHour = startDate.getHours();
    const endHour = endDate.getHours();
    const startMinutes = startDate.getMinutes();
    const endMinutes = endDate.getMinutes();

    const isBreak = extendedProps?.type === 'break';
    const isRest = extendedProps?.type === 'rest';

    // Ignore rest periods completely
    if (isRest) {
      return;
    }

    // Handle the start hour
    if (startMinutes > 0 && startHour < 24) {
      const fractionalStart = (60 - startMinutes) / 60;
      hours[startHour] += isBreak ? -fractionalStart : fractionalStart;
    } else if (startHour < 24) {
      hours[startHour] += isBreak ? -1 : 1;
    }

    // Handle the full hours in between
    for (let i = startHour + 1; i < endHour; i++) {
      if (i < 24) {
        hours[i] += isBreak ? -1 : 1;
      }
    }

    // Handle the end hour
    if (endMinutes > 0 && endHour < 24) {
      const fractionalEnd = 1 - endMinutes / 60;
      hours[endHour] += startHour !== endHour ? -fractionalEnd : fractionalEnd;
    } else if (startHour === endHour && endHour < 24) {
      const fractionalMiddle = (endMinutes - startMinutes) / 60;
      hours[startHour] += isBreak ? -fractionalMiddle : fractionalMiddle;
    }
  });

  for (let i = 0; i < hours.length; i++) {
    hours[i] = parseFloat(hours[i].toFixed(2));
  }

  return hours;
};

export const getAdjustedWeekday = (date: Date): number => {
  const day = date.getDay();
  return day === 0 ? 6 : day - 1;
};
