import { Dispatch, SetStateAction } from 'react';
import { z } from 'zod';

import { Periods } from 'modules/core/providers/InboundCalculator.provider';
import { CampaignInterface, TeamInterface } from 'modules/shared/interfaces';
import { Period, ShiftInterface } from 'modules/shifts/interfaces/shifts.interface';
import { DateInput, EventInput } from '@fullcalendar/core';
import { ConflictInterface, ConflictName, ConflictStatus } from './Conflict.interface';
import { Colors } from './Calendar.interface';
import { TypeModal } from 'modules/shared/components/modal/Modal.component';
import { AssistedCalculatorData, ExpectedCall } from './AssistedScheduler.interface';

export type SchedulerStatus = 'draft' | 'published' | 'finished';

export type InputValue = Date | string | number | null;

export type ICalendarEvent = {
  resourceId: string;
  title: string;
  title_break: string;
  id?: string;
  id_break?: string;
  start: DateInput | string | undefined;
  end: DateInput | string | undefined;
  break_start: DateInput | string | undefined;
  break_end: DateInput | string | undefined;
  extendedProps?: {
    breakDuration: number;
    currentPeriod: number;
    indexShift: number;
    workMinutes?: number;
    workHours?: number;
    type: string;
  };
  // relatedEvents: string[];
  color: string;
  allDay?: boolean;
};
// -----------------------------

export interface SchedulerInterface {
  name: string;
  start_date: Date | string | null;
  end_date: Date | string | null;
  status: SchedulerStatus;
  type: SchedulerTypes;
  campaign: CampaignInScheduler;
  planner: PlannerInScheduler;
  teams: TeamInScheduler[];
  employees: EmployeeInScheduler[];
  shifts?: ShiftInScheduler[];
  erlang?: AssistedCalculatorData;
  id?: string;
  finished_at?: string;
  erlang_inputs_id?: number;
  expected_calls_id?: number;
  expected_calls?: ExpectedCall;
}

export interface BreakInScheduler {
  date: DateInput | string | undefined;
  id?: string;
  break_category: string;
  start_break: DateInput | string | undefined;
  end_break: DateInput | string | undefined;
  breakId: string;
  colour?: Colors;
}

export interface PlannerInterface {
  id: string;
  names: string;
  surenames: string;
  fullname: string;
}

export type PlannerInScheduler = PlannerInterface;

export interface TeamInScheduler {
  id: string;
  name: string;
  manager?: { id: string; names: string; surenames: string };
}

export type CampaignInScheduler = Pick<CampaignInterface, 'id' | 'name'> & {
  teams?: TeamInterface[];
};

export type ShiftInScheduler = Pick<ShiftInterface, 'name' | 'shift_type'> & {
  id: string;
  period_count: number;
  periods: Period[];
  breaks: { id: string; name: string; duration: number; category: string }[];
};

export interface ScheduledInterface {
  date: Date | string | null;
  from_hour: Date | string | null;
  to_hour: Date | string | null;
  all_day: boolean;
  colour: Colors;
  conflict_status: ConflictStatus;
  total_scheduled_hours?: number;
  request_type?: ConflictName;
  request_id?: number;
  id?: string;
  className?: string;
  extendedProps?: {
    type: string;
  };
}

export interface RequestInScheduler {
  id: string;
  name: string;
  date: Date | string | null;
}

export interface EmployeeInScheduler {
  id?: string;
  names?: string;
  surenames?: string;
  employee: {
    id: string;
    names: string;
    surenames: string;
    fullname: string;
    assigned_shift?: {
      id: string;
      name: string;
      type?: string;
    };
    team?: TeamMember;
  };
  adherence?: Adherence;
  dates?: { [date: string]: { status: string } };
  scheduled: ScheduledInterface[];
  breaks: BreakInScheduler[];
  color?: Colors;
  request_created_by_conflict?: boolean;
  requests?: RequestInScheduler[];
  isInAnotherPlan?: boolean;
  hasAnotherRequest?: boolean;
  requestCreatedBasedOnConflict?: boolean;
  basedOnConflictRequestType?: 'cambio de turno' | 'hora extra';
  // Properties from SchedulableEmployees
  assigned_shift?: { id: string; name: string; type?: string };
  team?: { id: string; name: string };
  campaign?: { id: string; name: string };
  // dates are already included
}

export interface CreateEventsForDayParams {
  dayIndex: number;
  currentDay: Date;
  employee: SchedulableEmployees;
  currentPeriod: number;
  indexShift: number;
  shifts: ShiftInterface[];
  hasShift: boolean;
  counter: number;
  isWorkable: boolean;
  hasLicenseToday: boolean;
  events: EventInput[];
}

// -----------------------------

export interface SchedulerContextType {
  popupForm: PopupProps;
  setPopupForm: Dispatch<SetStateAction<PopupProps>>;
  quantityDays: QuantityDaysProps;
  setQuantityDays: Dispatch<SetStateAction<QuantityDaysProps>>;
  resetPopupForm: () => void;
  selectedDays: SelectedDaysProps;
  setSelectedDays: Dispatch<SetStateAction<SelectedDaysProps>>;
  selectedCampaign: CampaignInScheduler;
  setSelectedCampaign: Dispatch<SetStateAction<CampaignInScheduler>>;
  conflicts: ConflictInterface[];
  setConflicts: Dispatch<SetStateAction<ConflictInterface[]>>;
  resetSelectedDays: () => void;
  //
  employeeCounts: number[];
  setEmployeeCounts: Dispatch<SetStateAction<number[]>>;
}

export interface DataModal {
  isOpenModal: boolean;
  title: string;
  content: string;
  loadingBtnConfirm: boolean;
  typeModal: TypeModal;
  textBtnConfirm: string;
  handleConfirm: () => void;
  formattedTitle?: React.ReactNode;
}

export interface TeamMember {
  id: string;
  name?: string;
  isManager: boolean;
  campaign: { id: string; name: string };
}

export interface SelectedDaysProps {
  firstDay: string;
  lastDay: string;
  indexFirstDay: number;
  mondayOfTheWeek: Date;
  indexLastDay: number;
  totalDaysToSchedule: number;
  counterForLastSaturday: number;
  weeksBeingScheduled: number;
}

// -----------------------------
export const searchBarSchedulerResolver = z.object({
  teams: z
    .array(
      z.object({
        id: z.string().min(1),
        name: z.string().min(1),
        manager: z
          .object({
            id: z.string().min(1),
            names: z.string().min(1),
            surenames: z.string().min(1),
          })
          .optional(),
      }),
    )
    .min(1, 'Al menos 1 equipo es requerido'),
  state: z.string().min(1),
  start_date: z.string().min(1),
  end_date: z.string().min(1),
});
export type SearchBarSchedulerForm = z.infer<typeof searchBarSchedulerResolver>;

export enum EmployeeSchedulerStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export enum EmployeeWithLicenses {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}
//TODO ELEMINAR
export interface CampaignDummy {
  id: string;
  name: string;
  teams: {
    id: string;
    name: string;
  }[];
}

export interface PopupProps {
  name: string;
  start_date: string | null;
  end_date: string | null;
  type: SchedulerTypes;
  campaign: CampaignInScheduler;
  teams?: {
    id: string;
    name: string;
    member_count?: number;
    status?: string;
    manager?: {
      id: string;
      names: string;
      surenames: string;
    };
  }[];
}

export interface QuantityDaysProps {
  daysToSchedule: number;
  historicalDays: number;
  // areQuantityDaysEqual: boolean;
}
// -----------------------------

export enum SchedulerTypes {
  MANUAL = 'manual',
  ASSISTED = 'assisted',
}

// -----------------------------

const PeriodsSchema = z.nativeEnum(Periods);

const CalculatorDataSchema = z.object({
  campaign: z.string(),
  incomingContacts: z.number(),
  periodContacts: PeriodsSchema,
  averageInteractionTime: z.number(),
  serviceLevelRequired: z.number(),
  responseTime: z.number(),
  maxOccupation: z.number().optional(),
  reducers: z.number().optional(),
  averagePatience: z.number().optional(),
  weeklyHours: z.number().optional(),
});

const TeamSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const ShiftSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const SchedulerBreakSchema = z.object({
  date: z.union([z.date(), z.string()]).nullable(),
  start_break: z.string(),
  end_break: z.string(),
});

const ScheduledSchema = z.object({
  date: z.union([z.date(), z.string()]).nullable(),
  from_hour: z.string(),
  to_hour: z.string(),
  total_scheduled_hours: z.string().optional(),
});

const ColorsSchema = z.nativeEnum(Colors);

const EmployeeSchema = z.object({
  shiftId: z.string(),
  scheduled: z.array(ScheduledSchema),
  breaks: z.array(SchedulerBreakSchema),
  colour: ColorsSchema,
  isInAnotherPlan: z.boolean().optional(),
  hasAnotherRequest: z.boolean().optional(),
  requests: z
    .array(
      z.object({
        date: z.string(),
        name: z.string(),
      }),
    )
    .optional(),
  requestCreatedBasedOnConflict: z.boolean().optional(),
  requestType: z.enum(['cambio de turno', 'hora extra']).optional(),
});

export const SchedulerSchema = z.object({
  popup_form: z.object({
    name: z.string(),
    start_date: z.union([z.date(), z.string()]).nullable(),
    end_date: z.union([z.date(), z.string()]).nullable(),
  }),
  id: z.string(),
  status: z.enum(['draft', 'published', 'finished']),
  type: z.enum(['manual', 'assisted']),
  erlang: CalculatorDataSchema,
  teams: z.array(TeamSchema),
  shifts: z.array(ShiftSchema),
  employees: z.array(EmployeeSchema),
  finished_at: z.string().optional(),
});

// -----------------------------

export const defaultAssistedCalculatorData = {
  time_period: Periods.MINUTES_60,
  average_handling_time: 257,
  required_service_level: 80,
  target_answer_time: 20,
  max_occupancy: 85,
  shrinkage: 30,
  average_patience: 60,
  working_hours_week: 37.5,
};

export const schedulerEmptyModel: SchedulerInterface = {
  name: '',
  id: '',
  start_date: '',
  end_date: '',
  status: 'draft',
  campaign: {} as CampaignInterface,
  type: SchedulerTypes.MANUAL,
  erlang: defaultAssistedCalculatorData,
  planner: { id: '', names: '', surenames: '', fullname: '' },
  teams: [],
  shifts: [],
  employees: [],
};

export const schedulerFormEmptyModel: SchedulerInterface = {
  name: '',
  start_date: '',
  end_date: '',
  id: '',
  status: 'draft',
  type: SchedulerTypes.MANUAL,
  campaign: {} as CampaignInterface,
  erlang: {
    time_period: 15,
    average_handling_time: 0,
    required_service_level: 0,
    target_answer_time: 0,
    max_occupancy: undefined,
    shrinkage: undefined,
    average_patience: undefined,
    working_hours_week: undefined,
  },
  teams: [],
  planner: { id: '', names: '', surenames: '', fullname: '' },
  shifts: [],
  employees: [],
  finished_at: undefined,
};

//-----------------------------
export interface SchedulableEmployees {
  id?: string;
  names?: string;
  surenames?: string;
  assigned_shift?: { id: string; name: string; type?: string };
  team?: { id: string; name: string };
  campaign?: { id: string; name: string };
  dates?: { [date: string]: { status: string } };
  adherence?: Adherence;
}

export interface SanitizedSchedulableEmployee {
  id?: string;
  title: string;
  extendedProps: extendedProps;
}

export interface extendedProps {
  teamName: string;
  assignedShift: string;
  adherence: Adherence;
}

export interface Adherence {
  dailyavg: DailyAvg[];
  hourlyavg: HourlyAvg[];
}

export interface DailyAvg {
  weekday: number;
  adherence_percent: number;
}

export interface HourlyAvg {
  weekday: number;
  adherence_percent: number;
  hourly_range: string;
}

export interface EmployeesListProps {
  value?: SchedulableEmployees[];
  onChange: (users: SchedulableEmployees[]) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedSchedulableEmployees: SchedulableEmployees[];
}
