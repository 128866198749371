import { httpService } from 'modules/core/services/http.service';
import { formatDate, FormatDateOutputDash, downloadCSVFiles } from 'utils';

export const exportPlanData = async (
  startDate: string,
  endDate: string,
  loaderCallback: (loading: boolean) => void,
) => {
  loaderCallback(true);
  const response = await httpService.get({
    url: `exports`,
    params: {
      from: formatDate(startDate, FormatDateOutputDash.YYYYMMDD),
      to: formatDate(endDate, FormatDateOutputDash.YYYYMMDD),
    },
  });

  downloadCSVFiles(response.data);
  loaderCallback(false);
};
