import { AppDispatch } from 'store';
import { licensesActions } from 'store/slices/licenses.slices';
import { getConfiguration, getLicenses, putLicenses } from 'modules/shared/services';
import { licensesSelectedInterface } from 'modules/setup-config/adapters/putLicenses.adapter';
import { AcceptedTypes } from 'modules/setup-config/interfaces';
import { LicenseInterface } from 'modules/requests/interfaces/request.interface';
export const fetchLicenses = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(licensesActions.setLoading(true));

      const licenses = await getLicenses();
      dispatch(licensesActions.setLicenses(licenses));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(licensesActions.setLoading(false));
    }
  };
};

export const setLicense = (data: LicenseInterface) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(licensesActions.setLoading(true));

      dispatch(licensesActions.setLicense(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(licensesActions.setLoading(false));
    }
  };
};

export const patchLicenses = (data: licensesSelectedInterface) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(licensesActions.setLoading(true));

      const queryLicenses = await putLicenses(data);
      dispatch(licensesActions.patchLicenses(queryLicenses));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(licensesActions.setLoading(false));
    }
  };
};

export const fetchConfiguration = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(licensesActions.setLoading(true));

      const configuration = await getConfiguration();
      dispatch(licensesActions.setConfiguration(configuration));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(licensesActions.setLoading(false));
    }
  };
};

export const setLicenseConfigurationData = (data: AcceptedTypes) => {
  return (dispatch: AppDispatch) => {
    try {
      dispatch(licensesActions.setLoading(true));

      dispatch(licensesActions.setLicenseConfiguration(data));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(licensesActions.setLoading(false));
    }
  };
};