import { EventInput } from '@fullcalendar/core';
import { ICalendarEvent } from './Scheduler.interface';

export type FormValuesProps = ICalendarEvent;

export type CalendarFormProps = {
  selectedEvents: EventInput[] | null | undefined;
  onCancel: VoidFunction;
  onDeleteEvent: VoidFunction;
  onCreateUpdateEvent?: (newEvents: EventInput[]) => void;
};

export interface DayOption {
  date: string;
  value: string;
  selected: boolean;
}

export enum Colors {
  BREAK = '#D1C4E9',
  REGULAR = '#DFEBFF',
  REST = '#FFDCB9',
  EXTRA = '#FFE39B',
  CHANGE = '#BDE8BD',
  UNAVAILABLE = '#FFCDD2',
}
