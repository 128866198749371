import { httpService } from 'modules/core/services/http.service';
import { getCampaignsAdapter } from '../adapters';

export type QueryStringsParamsType = {
  field?: string;
  value?: string;
  page?: number;
};

export const getCampaigns = async (queryStringsParams?: QueryStringsParamsType) => {
  const res = await httpService.get({
    url: 'campaigns/all',
    params: queryStringsParams,
  });

  return getCampaignsAdapter(res);
};

export const getCampaignInfo = async (id: string) => {
  const res = await httpService.get({
    url: `campaigns/${id}`,
  });

  return res.data;
};
