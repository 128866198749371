import { formatDate, SpecialFormats } from './formatDate';

export const getLongDate = (date: string | undefined) => {
  if (!date) return '';

  const months: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const formattedDate = !date.includes('GMT') ? formatDate(date, SpecialFormats.PARSESTRING) || '' : date;
  const d = new Date(formattedDate);

  const dateToReturn = `${d.getDate()} de ${months[d.getMonth()]} de ${d.getUTCFullYear()}`;
  return dateToReturn;
};
