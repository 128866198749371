import { AxiosInstance } from 'axios';
import { baseAxiosInstance } from '../../core/axios';

class CharactersService {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  getCharacter(id: number) {
    return this.axiosInstance.get(`/people/${id}`);
  }
}

export const charactersService = new CharactersService(baseAxiosInstance);
