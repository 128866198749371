import { httpService } from 'modules/core/services/http.service';
import { getTagsAdapter } from '../adapters';

export const getTags = async (category?: string) => {
  const res = await httpService.get({
    url: category ? `tags/${category}` : 'tags',
  });

  return getTagsAdapter(res.data);
};
