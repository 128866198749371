import { httpService } from 'modules/core/services/http.service';
import { AcceptedTypes } from 'modules/setup-config/interfaces';

interface QueryStringParams {
  fieldsToReturn: 'all' | 'specific';
}

export const getLicenses = async () => {
  const res = await httpService.get({
    url: 'licenses',
  });

  return res.data;
};

export const putLicenses = async (data: any) => {
  const res = await httpService.put({
    url: 'licenses',
    payload: data,
  });
  return res.data;
};

export const getStatuses = async () => {
  const res = await httpService.get({
    url: 'status',
  });

  return res.data;
};
export const getUsers = async (queryStringParams: QueryStringParams) => {
  const res = await httpService.get({
    url: 'users',
    params: queryStringParams,
  });

  return res.data;
};

export const getConfiguration = async () => {
  const res = await httpService.get({
    url: 'configurator/license',
  });
  return res.data;
};

export const patchConfiguration = async (id: string, data: AcceptedTypes) => {
  const res = await httpService.put({
    url: `configurator/license/${id}`,
    payload: data,
  });
  return res.data;
};
