/* eslint-disable @typescript-eslint/no-unused-vars */
import { Charts } from 'modules/shared/interfaces';
import { convertTimeToMs } from 'utils';
import Visibility from '@mui/icons-material/Visibility';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';

const getDeviation = (value: number, relativeTarget: number) => {
  const difference = relativeTarget - value;
  if (difference > 0) {
    return (difference * 100) / relativeTarget;
  }
  return 0;
};
export const NO_RESULTS = 'no_results';
export const getCubeJSData = (
  resultSet: any[],
  chart: Charts,
  setOperatorQuery?: (query: any) => void,
  setOpenDetailModal?: Dispatch<SetStateAction<boolean>>,
  setOperatorDetailData?: Dispatch<SetStateAction<any>>,
) => {
  if (!resultSet || resultSet.length === 0) {
    return NO_RESULTS;
  }
  switch (chart) {
    case Charts.CALLS_METRIC:
      if (resultSet[0]['Calls.sumQuantity']) {
        const calls = resultSet[0]['Calls.sumQuantity'];
        const formattedCalls = calls.toLocaleString().replace(/,/g, '.');
        return {
          calls: formattedCalls,
        };
      } else {
        return NO_RESULTS;
      }
    case Charts.CALLS_METRIC_RELATIVE:
      if (resultSet[0]['OutboundCallsPerDay.calls']) {
        return {
          relativeValue: resultSet[0]['OutboundCallsPerDay.calls'],
        };
      } else {
        return NO_RESULTS;
      }

    case Charts.CALLS_METRIC_ABSOLUTE:
      if (resultSet[0]['OutboundCallsPerDayAbsolute.calls']) {
        return {
          absoluteValue: resultSet[0]['OutboundCallsPerDayAbsolute.calls'],
        };
      } else {
        return NO_RESULTS;
      }
    case Charts.EFFECTIVE_MANAGEMENT_METRIC:
      return {
        management: resultSet[0]['EffectiveManagment.sumEfectivas'],
        relativeValue: resultSet[0]['OutboundSuccessCallsPerDay.calls'],
        absoluteValue: resultSet[0]['OutboundSucessCallsPerDayAbsolute.calls'],
        deviation: getDeviation(
          resultSet[0]['EffectiveManagment.sumEfectivas'],
          resultSet[0]['OutboundSuccessCallsPerDay.calls'],
        ),
      };

    case Charts.TMO_METRIC:
      return resultSet;

    case Charts.TMO_METRIC_ABSOLUTE:
      return resultSet;

    case Charts.AVERAGE_TIME_CONVERSATION_METRIC:
      if (resultSet[0]['Outbound.callTime'] && resultSet[0]['UserStats.outboundTalkTimeAvgInTime']) {
        return {
          targetPlanTime: convertTimeToMs(resultSet[0]['Outbound.callTime']),
        };
      } else {
        return NO_RESULTS;
      }
    case Charts.REAL_AVERAGE_TIME_CONVERSATION_METRIC:
      if (resultSet[0]['UserStats.tiempoPromedioConversacion']) {
        return {
          averageTime: convertTimeToMs(resultSet[0]['UserStats.tiempoPromedioConversacion']),
        };
      } else {
        return NO_RESULTS;
      }
    case Charts.CALLS_CHART: {
      if (resultSet.length) {
        const parsedData = resultSet
          .sort((_a: any, _b: any) => {
            const a = format(new Date(_a['Calls.dateRange']), 't');
            const b = format(new Date(_b['Calls.dateRange']), 't');
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          })
          .map(item => ({
            date: item['Calls.dateRange'],
            valXAxis: format(new Date(item['Calls.dateRange']), 'yyyy-mm-dd'),
            originalDate: new Date(item['Calls.dateRange']),
            abandonedValue: item['Calls.abandonedSum'],
            successValue: item['Calls.asweredSum'],
            totalValue: item['Calls.sumQuantity'],
          }));
        return parsedData;
      } else {
        return NO_RESULTS;
      }
    }

    case Charts.EFFECTIVE_MANAGEMENT_CHART1: {
      return resultSet;
    }

    case Charts.EFFECTIVE_MANAGEMENT_CHART2: {
      return resultSet;
    }

    case Charts.HEADCOUNT: {
      const parsedData = resultSet.map((item, index) => ({
        valXAxis: index + 1,
        valYAxis: item['Outbound.tmkMax'],
        valYAxis2: item['LogInOut.userCount'],
        originalDate: new Date(item['LogInOut.dateRange']),
      }));
      return parsedData;
    }
    case Charts.OPERATORS_TABLE: {
      const rows = resultSet.map((row: any) => [
        { displayType: 'text', text: row['OperatorsReport.operadores'], id: 'operadores' },
        { displayType: 'text', text: row['OperatorsReport.llamadasSum'], id: 'llamadas' },
        { displayType: 'text', text: row['OperatorsReport.llamadasHoraSum'], id: 'llamadas/hora' },
        { displayType: 'text', text: row['OperatorsReport.gestionesEfectivasSum'], id: 'gestionesEfectivas' },
        { displayType: 'text', text: row['OperatorsReport.tiempoLoginSumTime'], id: 'tiempoLogin' },
        { displayType: 'text', text: row['OperatorsReport.reductoresSumTime'], id: 'reductores' },
        { displayType: 'text', text: row['OperatorsReport.contactosSum'], id: 'contactos' },
        { displayType: 'text', text: row['OperatorsReport.talkingTimeSumTime'], id: 'talkingTime' },
        // {
        //   displayType: 'iconButton',
        //   hidden: true,
        //   icon: <Visibility />,
        //   action: () => {
        //     setOperatorDetailData && setOperatorDetailData({});
        //     setOpenDetailModal && setOpenDetailModal(true);
        //     setOperatorQuery &&
        //       setOperatorQuery({
        //         measures: [
        //           'OperatorsReport.llamadasSum',
        //           'OperatorsReport.llamadasHoraSum',
        //           'OperatorsReport.gestionesEfectivasSum',
        //           'OperatorsReport.tiempoLoginSumTime',
        //           'OperatorsReport.contactosSum',
        //           'OperatorsReport.talkingTimeSumTime',
        //           'OperatorsReport.descansoSumTime',
        //           'OperatorsReport.tiempoAdminSumTime',
        //           'OperatorsReport.banoSumTime',
        //           'OperatorsReport.coachingSumTime',
        //           'OperatorsReport.acwSumTime',
        //           'OperatorsReport.rrssSumTime',
        //           'OperatorsReport.rrss2SumTime',
        //           'OperatorsReport.tiempoMarcadoSumTime',
        //           'OperatorsReport.tiempoPreviewCrmSumTime',
        //           'OperatorsReport.tiempoPostviewCrmSumTime',
        //           'OperatorsReport.tiempoOtrosCrmSumTime',
        //           'OperatorsReport.tiempoEsperaSumTime',
        //         ],
        //         timeDimensions: [
        //           {
        //             dimension: 'OperatorsReport.fecha',
        //             granularity: 'week',
        //           },
        //         ],
        //         filters: [
        //           {
        //             member: 'OperatorsReport.operador_id',
        //             operator: 'equals',
        //             values: [row['OperatorsReport.operador_id']],
        //           },
        //           // TODO -> see if we need to add more filters, review if this function gets called on 'plan de vuelo'
        //           // {
        //           //   member: 'OperatorsReport.idCampana',
        //           //   operator: 'equals',
        //           //   values: ['272'],
        //           // },
        //         ],
        //         dimensions: ['OperatorsReport.operadores'],
        //         order: {
        //           'OperatorsReport.llamadasSum': 'desc',
        //         },
        //       });
        //   },
        // },
      ]);
      return rows;
    }
    case Charts.OPERATOR_DETAIL: {
      const rows = resultSet.map((row: any, i: number) => [
        { displayType: 'text', text: `Semana ${i + 1}`, id: 'semana' },
        { displayType: 'text', text: row['OperatorsReport.llamadasSum'], id: 'llamadas' },
        { displayType: 'text', text: row['OperatorsReport.llamadasHoraSum'], id: 'llamadas/hora' },
        { displayType: 'text', text: row['OperatorsReport.gestionesEfectivasSum'], id: 'gestionesEfectivas' },
        { displayType: 'text', text: row['OperatorsReport.tiempoLoginSumTime'], id: 'tiempoLogin' },
        { displayType: 'text', text: row['OperatorsReport.contactosSum'], id: 'contactos' },
        { displayType: 'text', text: row['OperatorsReport.talkingTimeSumTime'], id: 'talkingTime' },
        { displayType: 'text', text: row['OperatorsReport.descansoSumTime'], id: 'breakTime' },
        { displayType: 'text', text: row['OperatorsReport.tiempoAdminSumTime'], id: 'adminTime' },
        { displayType: 'text', text: row['OperatorsReport.banoSumTime'], id: 'bathroom' },
        { displayType: 'text', text: row['OperatorsReport.coachingSumTime'], id: 'coaching' },
        { displayType: 'text', text: row['OperatorsReport.acwSumTime'], id: 'ACW' },
        { displayType: 'text', text: row['OperatorsReport.rrssSumTime'], id: 'RRSS' },
        { displayType: 'text', text: row['OperatorsReport.rrss2SumTime'], id: 'RRSS2' },
        { displayType: 'text', text: row['OperatorsReport.tiempoMarcadoSumTime'], id: 'dialTime' },
        { displayType: 'text', text: row['OperatorsReport.tiempoEsperaSumTime'], id: 'atentionTime' },
        { displayType: 'text', text: row['OperatorsReport.tiempoPreviewCrmSumTime'], id: 'previewCRMTime' },
        { displayType: 'text', text: row['OperatorsReport.tiempoPostviewCrmSumTime'], id: 'postviewCRMTime' },
        { displayType: 'text', text: row['OperatorsReport.tiempoOtrosCrmSumTime'], id: 'otherCRMTime' },
        { displayType: 'text', text: row['OperatorsReport.descansoSumTime'], id: 'freeTime' },
      ]);
      return { operatorName: resultSet && resultSet[0] ? resultSet[0]['OperatorsReport.operadores'] : '', rows };
    }
    default:
      break;
  }
  return {};
};
